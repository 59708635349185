<template>
  <div
    id="location-details"
  >
    <!-- Alert: No location found -->
    <b-alert
      variant="danger"
      :show="location === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('errors.data.error_fetching_request_data') }}
      </h4>
      <div class="alert-body">
        {{ $t('errors.data.no_request_found_with_this_id') }}.
      </div>
    </b-alert>
    <div v-if="location && location.id">
      <location-header :location="location" />
      <b-row>
        <b-col
          lg="8"
        >
          <location-details :data="location" />
        </b-col>
        <b-col
          lg="4"
        >
          <b-card
            id="locationRequest"
            no-body
          >
            <b-card-header>
              <b-card-title>
                <div class="d-flex">
                  <feather-icon
                    icon="PhoneCallIcon"
                    size="19"
                  />
                  <h4 class="mb-0 ml-50">
                    {{ $t('Service Request') }}
                  </h4>
                </div>
              </b-card-title>

            </b-card-header>
            <b-card-body>
              <location-add-request-button :location="location" />
            </b-card-body>
            <b-card-body>
              <request-add-timeline
                :location="location"
                sub-title-size="p font-italic"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BAlert, BRow, BCol, BCard, BCardBody, BCardTitle, BCardHeader,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import LocationHeader from '@/views/apps/location/details/LocationHeader.vue'
import LocationDetails from '@/views/apps/location/details/LocationDetails.vue'
import LocationAddRequestButton from '@/views/apps/location/details/LocationAddRequestButton.vue'
import RequestAddTimeline from '@/views/apps/service/request/RequestAddTimeline.vue'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    LocationHeader,
    LocationDetails,
    LocationAddRequestButton,
    RequestAddTimeline,
  },

  data() {
    return {

    }
  },

  setup() {
    const location = ref(null)

    // Get location  id from URL
    const { route } = useRouter()
    const locationId = route.value.params.id

    // Remote Data
    const fetchLocation = () => {
      store.dispatch('location/fetchLocation', { id: locationId })
        .then(response => {
          location.value = store.getters['location/Location']
        })
        .catch(error => {
          location.value = undefined
        })
    }

    fetchLocation()

    return {
      location,
    }
  },
}

</script>
<style scoped>

</style>
