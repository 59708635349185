<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="displayModal= true"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-25 d-none d-sm-inline"
      />
      <span>{{ $t('location.print') }}</span>
    </b-button>
    <b-modal
      id="print-location-modal"
      ref="print-location-modal"
      v-model="displayModal"
      centered
      hide-footer
      size="xl"
    >
      <div
        ref="printForm"
        :style="{backgroundImage:`url(${backgroundArrow})`}"
        class="text-dark arrow-up"
        :class="$i18n.locale"
      >
        <b-row>
          <b-col>
            <div class="d-flex justify-content-start align-items-center m-1">
              <b-img
                :src="appLogoImage"
                alt="logo"
                height="64px"
                class="mr-1 logo"
              />
              <div>
                <div class="font-large-1 text-primary font-weight-bold">
                  {{ appName }}<br>
                </div>
                <div class="font-medium-4 text-warning font-weight-bold">
                  {{ $t('about_the_project') }}
                </div>
              </div>

            </div>
            <div class="mt-2">
              <div class="mb-1 ml-1 line-height-inherit text-right font-medium-3">
                <span
                  v-for="(purpose,index) in getServicePurposes"
                  :key="index"
                >
                  <em>{{ getPurposeTitle(purpose) }}</em> <span class="text-secondary">  &#9679;  </span>
                </span>
                <em>{{ $t('point.type.other') }}... </em>
              </div>
              <b-img
                :src="backgroundCustomer"
                class="customer-contractor"
              />
              <div class="m-1">
                <div
                  v-for="(prop,index) in serviceRequestProps"
                  :key="index"
                  class="d-flex justify-content-start align-items-center mb-75 text-primary"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="24"
                    class="mr-1"
                  />
                  <div>
                    <div class="font-weight-bold font-medium-4">
                      {{ $t(prop) }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <Locale
              layout="buttons"
              class="d-flex justify-content-end align-items-center ml-1"
            />
          </b-col>
          <b-col>
            <!--            <div class="d-flex justify-content-center align-items-center m-1 text-primary">-->
            <!--              <feather-icon-->
            <!--                icon="PhoneCallIcon"-->
            <!--                class="mr-1"-->
            <!--                size="28"-->
            <!--              />-->
            <!--              <div class="align-middle text-primary font-large-1 font-weight-bold">-->
            <!--                {{ $t('Request services') }}-->
            <!--              </div>-->
            <!--            </div>-->
            <request-add-timeline
              :location="location"
              title-size="font-medium-3 font-weight-bold"
              sub-title-size="font-medium-2 font-italic"
              class="mt-1"
            />
          </b-col>

        </b-row>

      </div>

      <div class="text-center mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block

          @click="generatePDF(location)"
        >
          <span
            v-if="loading"
          >
            <b-spinner
              type="grow"
              small
            />
            <span class="align-middle"> {{ $t('Loading...') }}</span>
          </span>
          <span
            v-else
            class="align-middle"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            /> {{ $t('location.print') }}</span>

        </b-button>
      </div>

    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BModal, BSpinner, BImg, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useLocationUi } from '@/views/apps/location/useLocation'
// import { jsPDF } from 'jspdf'
import html2pdf from 'html2pdf.js'
import { $themeConfig } from '@themeConfig'
import RequestAddTimeline from '@/views/apps/service/request/RequestAddTimeline.vue'
import { getFormattedAddress } from '@/mixins/functions'
// import html2canvas from 'html2canvas'
import { ref } from '@vue/composition-api'
import { locales, serviceOptions } from '@/mixins/options'
import Locale from '@/layouts/components/app-navbar/components/Locale.vue'
import arrowUpImg from '@/assets/images/svg/arrow-up.svg'
import customerContractorImg from '@/assets/images/svg/customer-contractor.svg'

export default {
  components: {
    BButton,
    BModal,
    BSpinner,
    BImg,
    BRow,
    BCol,
    RequestAddTimeline,
    Locale,

  },
  directives: {
    Ripple,
  },
  mixins: [getFormattedAddress],
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },

  methods: {

    generatePDF() {
      this.loading = true
      const filename = this.getFormattedAddress(this.location.address)
        .concat('_')
        .concat(this.location.id).concat('_')
        .concat(this.$i18n.locale)
        .concat('.pdf')
      const element = this.$refs.printForm
      const opt = {
        margin: [0, 0, 0, 0],
        filename,
        image: { type: 'jpeg', quality: 75 },
        html2canvas: {
          dpi: 600,
          scale: 4,
          letterRendering: true,
          useCORS: true,
          disablePdfCompression: true,
        },
        jsPDF: {
          unit: 'mm', format: 'a4', orientation: 'landscape',
        },
      }

      html2pdf().from(element).set(opt).save()
        .then(() => {
          this.loading = false
          this.displayModal = false
        })
    },

    // generatePDF() {
    //   const element = this.$refs.printForm
    //   const doc = new jsPDF({
    //     orientation: 'p',
    //     unit: 'px',
    //     format: 'a4',
    //     hotfixes: ['px_scaling'],
    //   })
    //   html2canvas(element, {
    //     width: doc.internal.pageSize.getWidth(),
    //     height: doc.internal.pageSize.getHeight(),
    //   }).then(canvas => {
    //     const img = canvas.toDataURL('image/png')
    //     doc.text(10, 10, 'This is a test')
    //     doc.addImage(img, 'PNG', 10, 10, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())
    //     // doc.autoPrint({ variant: 'non-conform' })
    //     doc.save('autoprint.pdf')
    //   })
    // },
  },
  setup() {
    const backgroundArrow = arrowUpImg
    const backgroundCustomer = customerContractorImg
    const { localesList } = locales()
    const displayModal = ref(false)
    const loading = ref(false)
    const {
      locationAddRequest,
    } = useLocationUi()
    const { appName, appLogoImage } = $themeConfig.app
    const appUrl = window.location.hostname
    const { getPurposeTitle } = serviceOptions
    const getServicePurposes = [
      'home_master',
      'plumber',
      'electrician',
      'cleaning_of_apartments_and_houses',
      'painting_works',
      'air_conditioner_installation',
      'car_repair',
      'bicycle_repair',
      'transport_services',
      'veterinary_services',
      'interpreter',
      'manicure',
      'hairdressing_services',
      'clothing_repair_and_tailoring',
    ]
    const serviceRequestProps = [
      'Find a contractor quickly and easily',
      'Plan the date and time of the work',
      'Agree on the location and budget',
    ]

    return {
      displayModal,
      loading,
      locationAddRequest,
      appName,
      appLogoImage,
      appUrl,
      getServicePurposes,
      getPurposeTitle,
      localesList,
      serviceRequestProps,
      backgroundArrow,
      backgroundCustomer,
    }
  },
}
</script>
<style lang="scss" scoped>
.lang{
  margin-right: 0.2em;
}
.arrow-up{
  background-repeat: no-repeat, repeat;
  background-size: contain;
  background-position: 0 100%;
  height: 110%;

}
.customer-contractor{
  height: auto;
  margin:  0 20%;
  width: 70%;
}
.es .font-medium-3,
.en .font-medium-3,
.de .font-medium-3
{
  font-size: 1rem!important;
}

.es .font-medium-4,
.ru .font-medium-4,
.de .font-medium-4{
  font-size: 1.3rem!important;
}
@media (max-width: 720px) {
  .font-medium-3{
    font-size: 1rem!important;
  }
  .font-large-1{
    font-size: 1.6rem!important;
  }
}

</style>
